import React from "react"

import HeaderWrapper from "./header_wrapper"
import NavAboutUs from "./nav_aboutus"

export default function HeaderAboutUs({ heroTitle = null, children }) {
  const heroImage = "/images/slider/AboutUs--Banner.jpg"
  const heroMobileImage = "/images/slider/mobile/AboutUs--Banner.jpg"
  const breadcrumbSecondaryItem = "About Us"
  const breadcrumbSecondaryItemUrl = "/about-us/who-we-are"

  return (
    <HeaderWrapper
      heroTitle={heroTitle}
      heroImage={heroImage}
      heroMobileImage={heroMobileImage}
      breadcrumbSecondaryItem={breadcrumbSecondaryItem}
      breadcrumbSecondaryItemUrl={breadcrumbSecondaryItemUrl}
      NavComponent={NavAboutUs}
    >
      {children}
    </HeaderWrapper>
  )
}
