import React from "react"
import { Link } from "gatsby"

export default function NavAboutUs() {
  return (
    <>
      <Link to="/" className="back--home">
        <i class="fas fa-chevron-left"></i> <span>Back to homepage</span>
      </Link>

      <ul class="sub--pages sub--pages-sm">
        <li>
          <Link to="/about-us/who-we-are" activeClassName="is--active">
            Who we are
          </Link>
        </li>
        <li>
          <Link to="/about-us/our-philosophy" activeClassName="is--active">
            Our Philosophy
          </Link>
        </li>
        <li>
          <Link to="/about-us/why-choose-us" activeClassName="is--active">
            Why choose us?
          </Link>
        </li>
        {/* <li>
          <Link
            to="/about-us/association-with-ifast"
            activeClassName="is--active"
          >
            Association with iFast
          </Link>
        </li> */}
      </ul>
    </>
  )
}
