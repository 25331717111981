import React from "react"

import HeaderAboutUs from "../../components/header_aboutus"

export default function OurPhilosophy() {
  const heroTitle = "Our Philosophy"

  return (
    <HeaderAboutUs heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <p>
        We recommend long-term positions in well managed investment options.
        <br />
        When making and recommending an investment, our aim is to:
      </p>

      <ul class="list-icon list-icon-check list-icon-colored mt-4">
        <li>adopt a simple and stable approach to investment management</li>
        <li>preserve the value of capital</li>
        <li>maximize not only the risk adjusted returns but also stress adjusted returns</li>
        <li>maximize the quality of your portfolio through appropriate diversification</li>
        <li>remain insulated from “market fads and fancies” in the construction of portfolio</li>
      </ul>
    </HeaderAboutUs>
  )
}
